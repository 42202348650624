<template>
  <div
    class="container p-0 m-0 container-fluid d-flex flex-column c1-onboarding-home-container"
  >
    <div
      class="content-wrapper bg-wrapper"
      style="background-color: var(--cup-main-color-1)"
    >
      <ResponsiveImage
        file="background-kings-clg.webp"
        :img="imagesProps['background-kings-clg']"
        :screens="screens"
        pc-image
      />
      <InvitationExpiredModal></InvitationExpiredModal>
      <Header />
      <client-only>
        <LazyServiceAnnouncement></LazyServiceAnnouncement>
      </client-only>
      <div class="content container-fluid px-0 position-relative">
        <div class="row-wrapper">
          <div class="home-wrapper">
            <div class="row">
              <div class="col-xl-6 text-center order-1 order-xl-0">
                <div class="shield-container">
                  <div class="row g-0">
                    <div
                      class="offset-2 col-8 offset-md-0 col-md-12 position-relative"
                    >
                      <ResponsiveImage
                        file="shield.webp"
                        :img="imagesProps.shield"
                        pc-image
                      />
                      <ResponsiveImage
                        file="collage.webp"
                        :img="imagesProps.collage"
                        :screens="screens"
                        pc-image
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="offset-xl-2 col-xl-4 order-0 order-xl-1 text-center text-xl-start"
              >
                <div class="main-content">
                  <h1 class="heading1 mb-3">
                    {{ $t('ONBOARDING.HOME.HEADING1') }}
                  </h1>
                  <h2>{{ $t('ONBOARDING.HOME.HEADING2') }}</h2>
                  <p class="mb-4 mb-md-5 home-desc">
                    {{ $t('ONBOARDING.HOME.DESCRIPTION') }}
                  </p>
                  <p class="mb-0 d-flex home-actions align-items-center">
                    <NuxtLink
                      :to="
                        addQueryParams('/login', {
                          queryParams,
                          allowedQueryParams
                        })
                      "
                      qid="home-2"
                      class="btn btn-white-bg btn-started c1-btn-disabled login-btn"
                      :class="{ 'c1-btn-enabled': gigyaLoaded }"
                    >
                      {{ $t('ONBOARDING.HOME.ALREADY_REGISTERED') }}
                    </NuxtLink>
                    <NuxtLink
                      qid="home-1"
                      to="/regoptions"
                      class="btn btn-white-bordered btn-started c1-btn-disabled ms-3 signup-btn"
                      :class="{ 'c1-btn-enabled': gigyaLoaded }"
                    >
                      {{ $t('ONBOARDING.HOME.GET_STARTED') }}
                      <ResponsiveImage
                        file="long-arrow-right.webp"
                        :img="imagesProps['long-arrow-right']"
                        pc-image
                      />
                    </NuxtLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="marketing-main-wrapper">
      <section class="pb-3 pb-xl-5 section-1">
        <div class="marketing-container container-fluid">
          <div class="marketing-wrapper">
            <h2 class="marketing-heading">
              {{ $t('ONBOARDING.HOME.MARKETING_HEADING') }}
            </h2>
            <div class="row">
              <div
                class="col-xl-4 d-flex justify-content-center flex-column text-center text-xl-left order-1 order-xl-0"
              >
                <div class="d-xl-flex align-items-center mt-4 mt-md-5 mt-xl-0">
                  <ResponsiveImage
                    file="responsive-devices-icon.svg"
                    :img="imagesProps.responsiveDeviceIcon"
                    pc-image
                  />
                  <div class="ms-3 sub-heading mt-2 mt-md-3 mt-xl-0">
                    {{ $t('ONBOARDING.HOME.MARKETING_MESSAGE1') }}
                  </div>
                </div>
                <div class="row g-0">
                  <div class="offset-md-2 col-md-8 offset-xl-0 col-xl-12">
                    <p class="mt-2 mt-md-3 sub-text">
                      {{ $t('ONBOARDING.HOME.MARKETING_DESC1') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-8 order-0 order-xl-1 text-center text-xl-left">
                <ResponsiveImage
                  file="responsive-devices-img.webp"
                  :img="imagesProps.specialAboutCambridgeImage"
                  pc-image
                />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-xl-8 text-center text-xl-left">
                <ResponsiveImage
                  file="age-appropriate-img.webp"
                  :img="imagesProps.specialAboutCambridgeImage"
                  :screens="screens"
                  pc-image
                />
              </div>
              <div
                class="col-xl-4 d-flex justify-content-center flex-column text-center text-xl-left px-3"
              >
                <div class="d-xl-flex align-items-center mt-4 mt-md-5 mt-xl-0">
                  <ResponsiveImage
                    file="age-appropriate-icon.svg"
                    :img="imagesProps.ageAppropriateIcon"
                    pc-image
                  />
                  <div class="ms-3 sub-heading mt-2 mt-md-3 mt-xl-0">
                    {{ $t('ONBOARDING.HOME.MARKETING_MESSAGE2') }}
                  </div>
                </div>
                <div class="row g-0">
                  <div class="offset-md-2 col-md-8 offset-xl-0 col-xl-12">
                    <p class="mt-3 sub-text">
                      {{ $t('ONBOARDING.HOME.MARKETING_DESC2') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5 mb-3 mb-md-5">
              <div
                class="col-xl-4 d-flex justify-content-center flex-column text-center text-xl-left order-1 order-xl-0"
              >
                <div
                  class="d-xl-flex align-items-center mt-4 mt-md-5 mt-xl-0 pt-2 pt-xl-0"
                >
                  <ResponsiveImage
                    file="analytics-data-icon.svg"
                    :img="imagesProps.analyticsDataIcon"
                    pc-image
                  />
                  <div class="ms-3 sub-heading mt-2 mt-md-3 mt-xl-0">
                    {{ $t('ONBOARDING.HOME.MARKETING_MESSAGE3') }}
                  </div>
                </div>
                <div class="row g-0">
                  <div class="offset-md-2 col-md-8 offset-xl-0 col-xl-12">
                    <p class="mt-3 sub-text">
                      {{ $t('ONBOARDING.HOME.MARKETING_DESC3') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-8 order-0 order-xl-1 text-center text-xl-left">
                <ResponsiveImage
                  file="all-in-one-place-img.webp"
                  :img="imagesProps.specialAboutCambridgeImage"
                  :screens="screens"
                  pc-image
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section-2">
        <div class="marketing-container container-fluid">
          <div class="marketing-wrapper">
            <h2 class="marketing-heading">
              {{ $t('ONBOARDING.HOME.WHAT_CUSTOMERS_SAY') }}
            </h2>
            <div class="row px-1">
              <div class="col-xl-8">
                <div class="customer-review d-flex align-items-start">
                  <ResponsiveImage
                    file="user1.webp"
                    :img="imagesProps.userDesktop"
                    pc-image
                  />
                  <div class="ms-md-4 me-xl-2">
                    <p class="review-text">
                      {{ $t('ONBOARDING.HOME.CUSTOMER_REVIEW1') }}
                    </p>
                    <div class="customer-info mt-3 d-flex align-items-center">
                      <ResponsiveImage
                        file="user1.webp"
                        :img="imagesProps.userMobile"
                        pc-image
                      />
                      {{ $t('ONBOARDING.HOME.CUSTOMER1') }}
                    </div>
                  </div>
                </div>
                <div class="customer-review d-flex align-items-start">
                  <ResponsiveImage
                    file="user2.webp"
                    :img="imagesProps.userDesktop"
                    pc-image
                  />
                  <div class="ms-md-4 me-xl-2">
                    <p class="review-text">
                      {{ $t('ONBOARDING.HOME.CUSTOMER_REVIEW2') }}
                    </p>
                    <div class="customer-info mt-3 d-flex align-items-center">
                      <ResponsiveImage
                        file="user2.webp"
                        :img="imagesProps.userMobile"
                        pc-image
                      />
                      {{ $t('ONBOARDING.HOME.CUSTOMER2') }}
                    </div>
                  </div>
                </div>
                <div class="customer-review d-flex align-items-start">
                  <ResponsiveImage
                    file="user3.webp"
                    :img="imagesProps.userDesktop"
                    pc-image
                  />
                  <div class="ms-md-4 me-xl-2">
                    <div class="review-text pe-xl-3">
                      {{ $t('ONBOARDING.HOME.CUSTOMER_REVIEW3') }}
                    </div>
                    <div class="customer-info mt-3 d-flex align-items-center">
                      <ResponsiveImage
                        file="user3.webp"
                        :img="imagesProps.userMobile"
                        pc-image
                      />
                      {{ $t('ONBOARDING.HOME.CUSTOMER3') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 text-center mt-4 mt-xl-0 px-3">
                <div class="d-md-flex d-xl-block">
                  <ResponsiveImage
                    file="postive-reinforce.webp"
                    :img="imagesProps.positiveReinforce"
                    pc-image
                  />
                  <p
                    class="satisfied-customer mt-xl-3 px-md-2 order-0 text-center text-md-left text-xl-center"
                  >
                    <span
                      v-html="$t('ONBOARDING.HOME.POSITIVE_REINFORCE_MSG')"
                    ></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
const runtimeConfig = useRuntimeConfig();
const { activateTheme } = useThemeService();

const route = useRoute();
const { addQueryParams } = useHttpHelper();
const queryParams = route.query;
const allowedQueryParams = runtimeConfig.public.ielts.allowedQueryParams;

onBeforeMount(() => {
  activateTheme(runtimeConfig.public.themeTypes.adult.code);
});

const imagesProps = {
  collage: {
    class: 'people-logo position-absolute',
    alt: 'Students Collage'
  },
  shield: {
    class: 'mb-3 mb-md-5 img-fluid shield-logo',
    alt: 'Shield'
  },
  'long-arrow-right': {
    class: 'ms-sm-2',
    'aria-hidden': 'true'
  },
  'background-kings-clg': {
    class: 'bg-image',
    alt: ''
  },
  specialAboutCambridgeImage: {
    alt: 'Special about Cambridge One',
    class: 'img-fluid mktng-img',
    loading: 'lazy'
  },
  userDesktop: {
    alt: 'User',
    class: 'd-none d-md-block',
    loading: 'lazy'
  },
  userMobile: {
    alt: 'User',
    class: 'd-md-none me-3',
    loading: 'lazy'
  },
  positiveReinforce: {
    loading: 'lazy',
    alt: 'Positive Reinforce'
  },
  analyticsDataIcon: {
    class: 'img-fluid mktng-img',
    alt: '',
    loading: 'lazy'
  },
  responsiveDeviceIcon: {
    class: 'img-fluid mktng-img',
    alt: '',
    loading: 'lazy'
  },
  ageAppropriateIcon: {
    class: 'img-fluid mktng-img',
    alt: '',
    loading: 'lazy'
  }
};

const screens = {
  mob: { size: '576' },
  tab: { size: '768' },
  desktop: { size: 'min' },
  iwb: { size: 'org' }
};

definePageMeta({
  middleware: ['page-redirection']
});
</script>

<style lang="scss" scoped>
.c1-onboarding-home-container {
  max-width: 100% !important;
}

.bg-wrapper {
  background-size: cover;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  position: relative;

  .content-wrapper {
    z-index: 20;
    position: relative;
  }
  .bg-image {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  @include media-breakpoint-up(lg) {
    flex: 1 1 auto;
  }
  @include media-breakpoint-up(xl) {
    flex: 1 0 auto;
  }
  &.content-wrapper {
    @include media-breakpoint-up(xl) {
      background-size: inherit;
    }
  }

  .row-wrapper {
    padding: 0 15px;
  }

  .home-wrapper {
    color: var(--cup-general-color-1);
    margin: 32px 0 0;
    @include media-breakpoint-up(md) {
      margin: 32px 80px 0;
    }
    @include media-breakpoint-up(xl) {
      margin: 32px 65px 0;
    }
    .main-content {
      max-width: 450px;
      margin: 0 auto;
      .heading1 {
        font-size: 32px;
        font-weight: 300;
        line-height: 40px;
        @include media-breakpoint-up(md) {
          font-size: 48px;
          font-weight: 200;
          line-height: 56px;
          margin-top: 20px;
          padding: 4px 0;
        }
        @include media-breakpoint-up(xl) {
          margin-top: 80px;
        }
      }
      h2 {
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: 800;
        line-height: 24px;
        @include media-breakpoint-up(md) {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 32px;
        }
      }
      .home-desc {
        font-size: 14px;
        line-height: 24px;
        @include media-breakpoint-up(md) {
          font-size: 18px;
          line-height: 32px;
        }
      }
      .home-actions {
        justify-content: center;
        @include media-breakpoint-up(xl) {
          justify-content: flex-start;
        }
        a {
          border-radius: 24px;
          font-size: 14px;
        }
      }
      .login-btn {
        padding: 16px 20px;
        @include media-breakpoint-up(sm) {
          padding: 16px 32px;
        }
      }
      .signup-btn {
        padding: 9px 6px 9px 12px;
        @include media-breakpoint-up(sm) {
          padding: 9px 18px;
        }
      }
    }
    .shield-container {
      margin: 24px auto 0;
      @include media-breakpoint-up(md) {
        margin-top: 56px;
      }
      @include media-breakpoint-between(md, lg) {
        max-width: 500px;
      }
      @include media-breakpoint-up(xl) {
        margin: 0;
      }
      .shield-logo {
        @include media-breakpoint-up(md) {
          position: relative;
          left: 15px;
        }
      }
      .people-logo {
        max-width: 130%;
        left: 50%;
        bottom: 0px;
        transform: translateX(-50%);
        z-index: 1;
        @include media-breakpoint-up(md) {
          max-width: initial;
          max-width: 656px;
        }
      }
    }
  }
}

.marketing-main-wrapper {
  font-family: Mulish, Sans-serif;
}
.marketing-container {
  padding-left: 15px;
  padding-right: 15px;
  .marketing-wrapper {
    color: var(--cup-general-color-5);
    @include media-breakpoint-up(md) {
      margin: 0 25px;
    }
    @include media-breakpoint-up(xl) {
      margin: 0 65px;
    }
    .marketing-heading {
      padding: 64px 0;
      font-size: 21px;
      font-weight: 300;
      line-height: 32px;
      text-align: center;
      margin-bottom: 0;
      @include media-breakpoint-up(md) {
        font-size: 32px;
        line-height: 40px;
      }
    }
    .sub-heading {
      font-size: 21px;
      font-weight: 800;
      line-height: 32px;
      @include media-breakpoint-up(xl) {
        text-align: left;
      }
    }
    .sub-text {
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 32px;
      }
      @include media-breakpoint-up(xl) {
        text-align: left;
      }
    }
    .mktng-img {
      @include media-breakpoint-up(md) {
        max-width: 688px;
      }
      @include media-breakpoint-up(lg) {
        max-width: 736px;
      }
    }
    .customer-review {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
      border-radius: 16px;
      padding: 20px 16px;
      margin-bottom: 16px;
      background-color: var(--cup-general-color-1);
      @include media-breakpoint-up(md) {
        padding: 32px;
      }
      img {
        flex-shrink: 0;
        max-width: 80px;
      }
      .review-text {
        font-size: 21px;
        font-weight: 300;
        line-height: 32px;
        @include media-breakpoint-up(md) {
          font-size: 32px;
          line-height: 40px;
        }
      }
      .customer-info {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 0;
        @include media-breakpoint-up(md) {
          font-size: 18px;
          line-height: 32px;
        }
      }
    }
    .reinforce-img {
      height: 360px;
    }
    .satisfied-customer {
      font-size: 32px;
      font-weight: 300;
      line-height: 48px;
      @include media-breakpoint-up(md) {
        font-size: 40px;
        line-height: 56px;
      }
    }
  }
}
.section-1 {
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
  z-index: 1;
  position: relative;
}
.section-2 {
  background-color: #f8f8f8;
  padding-bottom: 48px;
  .marketing-heading {
    padding: 48px 0 !important;
  }
}
</style>
