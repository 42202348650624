<template>
  <div
    id="invitationExpiredModal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="invitationExpiredModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    :class="getExpiredModalVisibility() ? 'show d-block' : 'd-none'"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-body body text-center pt-5">
          <h1 id="invitationExpiredModalLabel" class="link-not-valid">
            {{ $t('ONBOARDING.HOME.LINK_NOT_VALID') }}
          </h1>
          <p class="check-with-teacher mt-4">
            {{ $t('ONBOARDING.HOME.CHECK_WITH_TEACHER') }}
          </p>
          <div class="text-center mb-3 mt-5">
            <button
              qid="iModal-1"
              type="button"
              class="btn btn-main-1 btn-lg"
              :aria-label="$t('ONBOARDING.HOME.EXPLORE')"
              data-bs-dismiss="modal"
              @click="hideExpiredModal()"
            >
              {{ $t('ONBOARDING.HOME.EXPLORE') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal-backdrop fade show"
    :class="getExpiredModalVisibility() ? 'd-block' : 'd-none'"
  ></div>
</template>

<script setup>
const { getInvitationExpiredModal, setInvitationExpiredModal } =
  useInvitationService();
const isInvitationExpiredModalVisible = ref(false);

onBeforeMount(() => {
  if (getInvitationExpiredModal()) {
    isInvitationExpiredModalVisible.value = true;
    setInvitationExpiredModal(false);
  }
});

const hideExpiredModal = () => {
  isInvitationExpiredModalVisible.value = false;
};

const getExpiredModalVisibility = () => {
  return isInvitationExpiredModalVisible.value;
};
</script>
<style lang="scss" scoped>
#invitationExpiredModal {
  width: 100%;
  padding: 0;
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
    .body {
      padding: 25px;
      .link-not-valid {
        color: var(--cup-general-color-5);
        font-size: 24px;
        font-weight: bold;
        line-height: 32px;
      }
      .check-with-teacher {
        font-size: 16px;
        line-height: 22px;
      }
      .btn-close {
        margin: auto;
        width: 205px;
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
      }
    }
  }
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: var(--cup-general-color-4);
  &.show {
    opacity: 0.4;
  }
}
</style>
